<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-05 20:39:21
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-13 14:04:34
-->
<template>
  <div>
    <modal :visible="visible" title="" @close="handleClose">
      <span slot="title" style="font-size: 20px;">
        提现<span style="font-size: 16px;">（提现仅提现到微信）</span>
      </span>
      <div class="modal-body">
        <el-form class="modal-form" ref="ruleForm" :model="form" :rules="rules">
          <el-form-item label="提现金额" prop="money">
            <el-input
              type="number"
              placeholder="请输入内容"
              class="withdrawal-input"
              min="100"
              max="880000"
              prefix-icon="el-icon-coin"
              v-model="form.money"
            >
            </el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="onSubmit">
          确 定
        </el-button>
      </span>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";
export default {
  components: {
    Modal
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (value === "") {
        return callback(new Error("请输入提现金额"));
      } else if (value < 100) {
        return callback(new Error("提现金额最少100元"));
      } else if (value % 100 != 0) {
        return callback(new Error("提现金额必须是100的倍数"));
      } else {
        return callback();
      }
    };
    return {
      visible: false,
      form: {
        money: 0
      },
      rules: {
        money: [{ validator: checkPhone, trigger: "blur" }]
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    },
    user() {
      return this.$store.getters.user;
    }
  },
  mounted() {},
  methods: {
    init() {
      this.form.money = 0;
      this.visible = true;
      if (this.$refs.ruleForm) {
        this.$refs.ruleForm.resetFields();
      }
    },
    handleClose() {},
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.user && this.user.openId) {
            let params = { customerId: this.userId, ...this.form };
            const loading = this.$loading({
              lock: true,
              text: "提现中...",
              spinner: "el-icon-loading",
              background: "rgba(0, 0, 0, 0.7)"
            });
            this.$api
              .setWallet(params)
              .then(() => {
                this.$parent.getUserInfo();
                this.$parent.gettWalletInfo();
                this.$message({
                  message: "恭喜您，提现成功！",
                  type: "success"
                });
                this.visible = false;
              })
              .catch(err => {
                this.$message.error(err?.msg);
              })
              .finally(() => {
                loading.close();
              });
          } else {
            this.$message.error("请先登录小程序！");
          }
          // openId
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped></style>
